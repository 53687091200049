@import './variables.scss';
.pss-sidebar{
    position: relative;
    height: fit-content;
    width: 30%;
    margin-right: 16px;
    max-height: 100vh;
    overflow-y: scroll;
    height:fit-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 0;
    padding: 48px 20px;
    background-color: $white-12;

    /* Style the buttons that are used to open and close the accordion panel */
    .sidebar-section {
        color: $black-10;
        cursor: pointer;
        padding: 18px 0 18px;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        white-space: nowrap;
        font-weight: bold;
        margin-bottom: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
    }

    /* Style the accordion panel. Note: hidden by default */
    .sidebar-topics {
        display: none;
        overflow: hidden;
        flex-direction: column;
        width: fit-content;
        position: relative;
        list-style-position: outside;
        list-style-type: square;

        li{
            margin-bottom: 12px;

            &::marker{
                color: $green-10;
                font-size: 1em;  
            }

            &:hover, &.active{
                list-style-type: disc;
                &::marker{
                    color: $teal-10;
                }
            }
            
            a{
                padding: 8px 12px;
                display: inline;
                line-height: 34px;
                box-sizing: border-box;
                box-decoration-break: clone;
                color: $black-10;

                 &.active, &:hover{
                    color: $black-10;
                    background-color: $yellow-10;
                }
            }
        }
    }    
}

@media  screen and (min-width: 0px) and (max-width: $tablet) {
    .pss-sidebar{
        flex-direction: row;
        width: 100%;
        padding: 0 8px;
        background-color: transparent;
        margin: 0 0 16px;
        box-sizing: border-box;
        overflow-y: hidden;
        overflow-x: scroll;

        .sidebar-section{
            margin: 0 16px 0 0;
            padding: 16px; 
            background-color: transparent;
            justify-content: center;
            box-sizing: border-box;
            position: relative;
            margin-right: 16px;
            height: fit-content;

            p{
                line-height: 16px;
            }

            &.selected, &:active, &:focus{
                color: $white-10;
                background: $pm_gradient;
            

                &#magic_cutter{
                    background: $mc_gradient;
                }

                &#cool_lam{
                    background: $cl_gradient;
                }

            }
        
        }
        .sidebar-topics{
            display: none !important;
        }
    }
}