@import './variables.scss';

.af-class-logo_component-slider {
     animation: scroll-left 4s linear forwards;
     width: max-content !important;
     margin-left: 100%;
 }

 .af-class-logo-slider-img{
    width: auto !important;
 }

 @keyframes scroll-left {
     0% {
         transform: translateX(0%);
     }

     100% {
         transform: translateX(calc(-100%));
     }
 }


@media screen and (min-width: 0px) and (max-width: $tablet) {
    .w-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .w-col-6,
    .w-col-5,
    .w-col-4,
    .w-col-8 {
    width: 100% !important;
    }

    .af-class-logo_component-slider {
        animation: scroll-left 10s linear infinite;
    }


 @keyframes scroll-left {
     0% {
         transform: translateX(0%);
     }

     100% {
         transform: translateX(calc(-100% - 100vw));
     }
 }
}

