@import './variables.scss';

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    max-width: 1208px;
    padding-left: 64px;
    padding-right: 64px;
    border: none;
    box-sizing: border-box;
    margin: 0 auto;
}

.pm {
    background: $pm_gradient;

    button {
        &:hover {
            a {
                color: $red-10;
            }
        }
    }
}


.mc {
    background: $mc_gradient;

    button {
        &:hover {
            a {
                color: $blue-10;
            }
        }
    }
}

.cl {
    background: $cl_gradient;

    button {
        &:hover {
            a {
                color: $teal-10;
            }
        }
    }
}

.help-section{
    overscroll-behavior: none;
    .help-product{
        width: 100%;
        margin: 0 0 0 auto;
        height: 100vh;
        position: relative;
        overflow-y: scroll;
        padding: 48px 24px;
        scroll-behavior: smooth;
        box-sizing: border-box;
        background-color: $white-12;
        h3, h4{
            font-weight: 700;
            margin-bottom: 1em;
        }

        .product-header{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            h1{
                height: fit-content;
                padding-bottom: 4px;
                font-weight: 700;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            img{
                margin-right: 40px;
                height: 150px;
            }

        }

        ol{
            li{
                font-weight: 700;
                margin-bottom: .5em;

                p{
                    font-weight: 500;
                    margin-left: 2px;
                }

                h5{
                    margin-left: 40px;
                    font-weight: 300;
                }
            }
        }

        .table-section{
            position: relative;
            display: flex;
            width: 100%;
            overflow-x: scroll;
            padding-bottom: 12px;
        }

        table{
            width: 100%;
            max-width: 450px;
            
            &,tr, th, td{
            border: 2px solid $black-10;
            padding: 8px;
            border-collapse: collapse;
            text-align: left;
            }

            th{
                background-color: $teal-8;
            }

            tr:nth-child(odd){
                background-color: $teal-5;
            }
        }

        .section-break{
            block-size: 2em;
        }

        .notes{
            border: 1px solid $black-10;
            background-color: $teal-5;
            padding: 12px;
            border-radius: 4px;

        }
    }
}

@media screen and (min-width: 0px) and (max-width: $mobile) {
    .container {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media  screen and (min-width: 0px) and (max-width: $tablet) {
    .help-section{
        flex-direction: column;

        .help-product{

              .product-header{
                flex-direction: column;

                img{
                    margin-top: 32px;
                }
            }
        }
    }
}